<template>
  <CommonPage id="privacy" title="FactLine">
    <section id="privacy-content" class="pc_padding">
      <div class="common-content-head">
        Information Collection
      </div>
      <div class="common-content-row">
        We collect user information through Firebase to optimize website performance. The
        information we collect includes but is not limited to:
        <ul>
          <li>Browser type</li>
          <li>Access times and dates</li>
          <li>Pages and features used</li>
        </ul>
      </div>

      <div class="common-content-head">
        Use of Information
      </div>
      <div class="common-content-row">
        The information we collect will be used for the following purposes:
        <ul>
          <li>Improving website performance and user experience</li>
          <li>Analyzing user behavior and trends</li>
          <li>Maintaining and enhancing our services</li>
        </ul>
      </div>

      <div class="common-content-head">
        Information Sharing
      </div>
      <div class="common-content-row">
        We do not sell, trade, or otherwise transfer your personal information to external
        parties, except in the following cases:
        <ul>
          <li>Compliance with laws or government requests</li>
          <li>Protecting our rights, property, or safety</li>
          <li>In the event of a merger, acquisition, or asset sale</li>
        </ul>
      </div>

      <div class="common-content-head">
        Security
      </div>
      <div class="common-content-row">
        We take reasonable measures to protect your personal information from unauthorized
        access, use, or disclosure. However, internet transmission is not completely secure,
        and we cannot guarantee absolute security.
      </div>

      <div class="common-content-head">
        Children's Privacy
      </div>
      <div class="common-content-row">
        We do not knowingly collect personal information from children under the age of 13.
        If we become aware that we have inadvertently collected such information, we will take
        steps to delete it as soon as possible.
      </div>

      <div class="common-content-head">
        Modification of Privacy Policy
      </div>
      <div class="common-content-row">
        We reserve the right to modify this privacy policy at any time. The modified policy
        will be posted on the Website and will become effective immediately.
      </div>
    </section>
  </CommonPage>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
import CommonPage from '@/pages/components/pc_common.vue';

import '@/css/common.scss';

export default {
  name: 'PrivacyMain',
  components: {
    CommonPage
  },
  mixins: [common],
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'showDebug',
      'deviceType',
      'host',
    ])
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
  methods: {
  }
}
</script>
